body {
	margin: 0;
	box-sizing: border-box;
	background-color: #F5F7FB;
	color: #293264;
	font-family: "Karla", sans-serif;
}

main {
	position: relative;
	min-height: 100vh;
}

p, h1, h2, h3 {
	margin: 0;
}

img {
	-webkit-user-drag: none;
}

footer {
	color: #293264;
	text-align: center;
	padding: 2em 0 2em 0;
	width: 200px;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -100px;
}

.btn-primary {
	background-color: #4D5B9E;
	color: #F5F7FB;
	font-family: "Inter", sans-serif;
	font-weight: 500;
	font-size: 1em;
	border-radius: 15px;
	width: 190px;
	height: 50px;
	border: 0;
	cursor: pointer;
	z-index: 100;
}

.btn-primary:active {
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.75) inset;
}