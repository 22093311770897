.shape-top {
	position: absolute;
	right: 0;
	width: 20vw;
	z-index: -1;
}

.shape-bottom {
	position: absolute;
	bottom: 0;
	width: 18vw;
	z-index: -1;
}

.game-title {
	font-weight: 700;
	font-size: 2em;
	text-align: center;
}

.game-intro {
    height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.game-intro h1 {
	margin: 0;
}

.game-intro p {
	margin: 2em 0 2em 0;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	font-size: 1em;
}

.game-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

.game-text {
	text-align: center;
}

.gameOptions-container {
	margin-bottom: 1em;
}

.select-container {
	margin-bottom: 1.1em;
	width: 550px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.custom-select {
	border: 0;
	font: inherit;
	width: 100%;
	height: 3em;
	padding: 0 1em 0 1em;
	color: #293264;
	border-radius: 0.25em;
	box-shadow: 0 0 0.6em 0 rgba(0, 0, 0, 0.2);
	cursor: pointer;
}

.custom-label {
	font-size: 1.1em;
	font-weight: 700;
	margin-right: 1.5em;
	width: 250px;
}

.noQuestions-text {
	margin-bottom: 1.5em;
	text-align: center;
}

@media screen and (max-width: 625px) {
	.game-container {
		position: static;
		top: unset;
		left: unset;
		transform: none;
		padding: 2em 1em 0 1em;
	}

	.shape-top {
		width: 25vw;
	}

	.shape-bottom {
		position: static;
		width: 40vw;
		z-index: -1;
	}

	.game-intro {
		padding: 0 1.5em 0 1.5em;
		height: 85vh;
	}

	.custom-label {
		margin-right: 10px;
		width: 160px;
	}

	.select-container {
		width: 100%;
	}
}

@media screen and (max-height: 800px) and (orientation: landscape) {
	.game-container {
		position: static;
		top: unset;
		left: unset;
		transform: none;
		padding: 2em 0;
		width: 700px;
		margin-right: auto;
		margin-left: auto;
	}

	.shape-bottom {
		position: static;
		width: 10vw;
		z-index: -1;
	}
}